import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ButtonBoot from 'react-bootstrap/Button';
import Button from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import Form from 'react-bootstrap/Form';
import { fetchData } from '../redux/cars/ownerSlice';
import { useUser, useToken } from '../components/auth';
import { editCar } from '../redux/cars/carSlice';
import { useNavigate } from 'react-router';

export default function EditCar({ data }) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const { ownersData, isSuccess } = useSelector((state) => state.owners);
  const [token] = useToken();
  const user = useUser();
  const { id } = user;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchData(token));
  }, [dispatch, token]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const saveCar = (id, token, carId) => {
    const owner = document.querySelector('#owners').value;
    const plate = document.querySelector('#plate').value.toUpperCase();
    const chassis = document.querySelector('#chassis').value;
    const type = document.querySelector('#type').value;
    const regdate = document.querySelector('#regdate').value;
    const reqBody = {
      datas: { owner, plate, chassis, type, regdate, user: id, carId },
      token,
    };

    dispatch(editCar(reqBody))
      .then((response) => {
        if (response.payload.message === 'Car edited') {
          handleClose();
          navigate('/cars');
        } else {
          document.querySelector(
            '#messagess'
          ).innerHTML = `<span style="color:red">${response.payload.message}</span>`;
        }
      })
      .catch((error) => {
        document.querySelector('#message').innerHTML = error;
      });
  };
  let owners = [];
  if (isSuccess) owners = ownersData.ownerData.map((owner) => owner);

  const newOwners = owners.map((own) => {
    if (own.id === data.owner) {
      return { ...own, selected: 'selected' };
    } else {
      return { ...own, selected: '' };
    }
  });

  return (
    <>
      <div className="cars-add-btn-dv">
        <Button
          style={{ width: '150px', marginRight: '20px', marginTop: '20px' }}
          variant="outlined"
          startIcon={<CreateOutlinedIcon />}
          onClick={handleShow}
        >
          Edit
        </Button>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit CAR</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="owners">
            <Form.Select aria-label="Default select example">
              <option value="">Select Owner</option>
              {newOwners.map((option) => (
                <option
                  key={option.id}
                  selected={option.selected}
                  value={option.id}
                >
                  {option.fname} {option.lname}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <FloatingLabel controlId="plate" label="Plate" className="mb-3">
            <Form.Control
              type="text"
              defaultValue={data.plate}
              placeholder="Plate"
            />
          </FloatingLabel>
          <FloatingLabel controlId="chassis" label="Chassis" className="mb-3">
            <Form.Control
              type="text"
              defaultValue={data.chassis}
              placeholder="Chassis"
            />
          </FloatingLabel>
          <Form.Group className="mb-3" controlId="type">
            <Form.Select aria-label="Default select example">
              <option value="">Type</option>
              {(data.type === 'COASTER' && (
                <option selected value="COASTER">
                  COASTER
                </option>
              )) || <option value="COASTER">COASTER</option>}
              {(data.type === 'MINI BUS' && (
                <option selected value="MINI BUS">
                  MINI BUS
                </option>
              )) || <option value="MINI BUS">MINI BUS</option>}
            </Form.Select>
          </Form.Group>
          <FloatingLabel controlId="regdate" label="Registration date">
            <Form.Control
              type="date"
              defaultValue={data.regdate}
              placeholder="Registration date"
            />
          </FloatingLabel>

          <p id="messagess"></p>
        </Modal.Body>
        <Modal.Footer>
          <ButtonBoot variant="secondary" onClick={handleClose}>
            Close
          </ButtonBoot>
          <ButtonBoot
            variant="primary"
            onClick={() => saveCar(id, token, data.id)}
          >
            Save changes
          </ButtonBoot>
        </Modal.Footer>
      </Modal>
    </>
  );
}
