import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { useUser, useToken } from './auth';

export const PrivatePages = ({ path }) => {
  const user = useUser();
  const [token] = useToken();

  if (!user) return <Navigate to="/" replace />;

  const message = async (token) => {
    const { username } = user;
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}/user-verify/${username}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.request(config);
      if (response.data.message === 'User okay') return 'yes';
      localStorage.removeItem('token');
      return null;
    } catch (error) {
      console.log(error);
      localStorage.removeItem('token');
      return null;
    }
  };

  (async () => {
    const me = await message(token);
    if (!me) return <Navigate to="/" replace />;
  })();

  return path;
};
