import { useState, useEffect } from 'react';
import axios from 'axios';
import LeftNav from '../components/Leftnav';
import TopBar from '../components/Topbar';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../css/Addacgroup.css';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useUser, useToken } from '../components/auth';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router';
import moment from 'moment';

export default function Parcels() {
  const navigate = useNavigate();
  const url = process.env.REACT_APP_BASE_URL;
  const [token] = useToken();
  const user = useUser();
  const { id, type } = user;
  const [message, setMessage] = useState('');
  const [searchMessage, setSearchMessage] = useState('');
  // Getting existing users
  const [gareDataa, setGareDataa] = useState([]);
  const defaultTab = type === 'Parcels' ? 'add' : 'parcels';

  useEffect(() => {
    const userData = async () => {
      const time = moment().format().substring(0, 10);
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/parcels?sdate=${time}&edate=${time}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setGareDataa(data);
      } catch (error) {
        console.log(error);
      }
    };
    userData();
  }, [token, url]);

  // searching

  async function search() {
    setGareDataa([]);
    const sdate = document.querySelector('#s-date').value;
    const edate = document.querySelector('#e-date').value;
    if (sdate === '' || edate === '') {
      setSearchMessage('Please select start date and end date');
      document.querySelector('#searchMessage').style.color = 'red';
    } else {
      setSearchMessage('Tegereza.....');
      document.querySelector('#searchMessage').style.color = 'blue';

      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/parcels?sdate=${sdate}&edate=${edate}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setGareDataa(data);
        if (data.parcelDatas.length !== 0) {
          setSearchMessage('');
        } else {
          setSearchMessage(`No data found from ${sdate} to ${edate}`);
          document.querySelector('#searchMessage').style.color = 'green';
        }
      } catch (error) {
        console.log(error);
        setSearchMessage('Error while retrieving data');
        document.querySelector('#searchMessage').style.color = 'red';
      }
    }
  }

  async function saveParcels() {
    const datee = document.querySelector('#date').value;
    const sender = document.querySelector('#sender').value;
    const receiver = document.querySelector('#receiver').value;
    const phone = document.querySelector('#phone').value;
    const sphone = document.querySelector('#sphone').value;
    const price = document.querySelector('#price').value;
    const pprice = document.querySelector('#p-price').value;
    const from = document.querySelector('#from').value;
    const destination = document.querySelector('#destination').value;

    if (
      sender === '' ||
      receiver === '' ||
      phone === '' ||
      price === '' ||
      from === '' ||
      destination === '' ||
      sphone === '' ||
      pprice === ''
    ) {
      setMessage('Please fill out all the required fields');
    } else {
      try {
        const datass = JSON.stringify({
          sender,
          datee: datee,
          receiver,
          phone,
          price,
          from,
          destination,
          sphone,
          pprice,
          user: id,
        });
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${url}/parcels`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          data: datass,
        };

        const { data } = await axios.request(config);
        if (data.message === 'Percal data added') {
          document.querySelector('#date').value = '';
          document.querySelector('#sender').value = '';
          document.querySelector('#receiver').value = '';
          document.querySelector('#phone').value = '';
          document.querySelector('#price').value = '';
          document.querySelector('#from').value = '';
          document.querySelector('#destination').value = '';
          setMessage(data.message);
          navigate('/parceldata', { state: { row: data.parcelDatas } });
        }
        setMessage(data.message);
      } catch (error) {
        console.log(error);
        setMessage(`Parcel data could not be added`);
      }
    }
  }

  // displaying garages datas

  return (
    <div className="App">
      <div className="left-side">
        <LeftNav />
      </div>
      <div className="right-side">
        <div>
          <TopBar page="Parcels" />
        </div>
        {/* Tab here */}
        <br />
        <div className="cars-add-btn-dv">
          <input name="s-date" id="s-date" type="date" />
          <span style={{ width: '30px' }}> </span>
          <input name="e-date" id="e-date" type="date" />
          <span style={{ width: '10px' }}> </span>
          <Button onClick={search} variant="contained">
            SEARCH
          </Button>
        </div>
        <div style={{ marginLeft: '20px' }}>
          <p id="searchMessage">{searchMessage}</p>
        </div>
        <Tabs
          defaultActiveKey={defaultTab}
          id="uncontrolled-tab-example"
          className="mb-2"
        >
          <Tab eventKey="parcels" title="Parcels">
            {Object.keys(gareDataa).length !== 0 && (
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead style={{ background: 'black' }}>
                    <TableRow className="">
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>Sender</h6>
                      </TableCell>
                      <TableCell align="right">
                        {' '}
                        <h6 style={{ color: '#fff' }}>Receiver</h6>
                      </TableCell>
                      <TableCell align="right">
                        <h6 style={{ color: '#fff' }}>Price</h6>
                      </TableCell>
                      <TableCell align="right">
                        <h6 style={{ color: '#fff' }}>Destination</h6>
                      </TableCell>
                      <TableCell align="right">
                        <h6 style={{ color: '#fff' }}>Date</h6>
                      </TableCell>
                      <TableCell align="right">
                        <h6>More</h6>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {gareDataa.parcelDatas.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.sender}
                        </TableCell>
                        <TableCell align="right">{row.receiver}</TableCell>
                        <TableCell align="right">{row.price}</TableCell>
                        <TableCell align="right">{row.destination}</TableCell>
                        <TableCell align="right">
                          {row.time
                            .toLocaleString('en-GB', {
                              timeZone: 'Africa/Kigali',
                            })
                            .substring(0, 10)}
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            variant="outlined"
                            onClick={() =>
                              navigate('/parceldata', { state: { row } })
                            }
                            startIcon={<InfoIcon />}
                          >
                            More
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    {Object.keys(gareDataa).length === 0 && (
                      <p>Loading........</p>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Tab>
          <Tab eventKey="add" title="Add a Parcel">
            {/*Add user here */}
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': {
                  m: 1,
                  width: '30ch',
                  marginTop: '20px',
                },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField id="sender" label="Sender*" defaultValue="" />
                <TextField
                  id="sphone"
                  label="Sender Phone*"
                  type="text"
                  defaultValue=""
                />
              </div>

              <div>
                <TextField id="receiver" label="Receiver*" defaultValue="" />
                <TextField id="phone" label="Reciever Phone*" defaultValue="" />
              </div>

              <div>
                <TextField
                  id="price"
                  type="number"
                  label="Price*"
                  defaultValue=""
                />

                <TextField
                  id="p-price"
                  type="number"
                  label="Price of Parcels*"
                  defaultValue=""
                />
              </div>

              <div>
                <TextField id="from" label="From*" defaultValue="" />
                <TextField
                  id="destination"
                  label="Destination*"
                  defaultValue=""
                />
              </div>

              <div>
                <TextField id="date" type="date" defaultValue="" />
              </div>

              <p style={{ marginLeft: '15%' }}>{message}</p>
              <div style={{ marginLeft: '20%', marginTop: '2%' }}>
                <Button variant="contained" onClick={saveParcels}>
                  Save
                </Button>
              </div>
            </Box>

            {/* End of add a user */}
          </Tab>
        </Tabs>
        {/* Tabs ends here */}
      </div>
    </div>
  );
}
