import { configureStore } from '@reduxjs/toolkit';
import carsReducer from './cars/carSlice';
import ownerReducer from './cars/ownerSlice';

const store = configureStore({
  reducer: {
    cars: carsReducer,
    owners: ownerReducer,
  },
});

export default store;
