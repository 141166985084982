import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { fetchData } from '../redux/cars/ownerSlice';
import { useUser, useToken } from '../components/auth';
import { addCar } from '../redux/cars/carSlice';

export default function AddCar() {
  const [show, setShow] = useState(false);
  const { ownersData, isSuccess } = useSelector((state) => state.owners);
  const [token] = useToken();
  const user = useUser();
  const { id } = user;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchData(token));
  }, [dispatch, token]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const saveCar = (id, token) => {
    const owner = document.querySelector('#owners').value;
    const plate = document.querySelector('#plate').value.toUpperCase();
    const chassis = document.querySelector('#chassis').value;
    const type = document.querySelector('#type').value;
    const regdate = document.querySelector('#regdate').value;
    const reqBody = {
      datas: { owner, plate, chassis, type, regdate, user: id },
      token,
    };

    dispatch(addCar(reqBody))
      .then((response) => {
        if (response.payload.message === 'Car added') handleClose();
        document.querySelector(
          '#message'
        ).innerHTML = `<span style="color:red">${response.payload.message}</span>`;
      })
      .catch((error) => {
        document.querySelector('#message').innerHTML = error;
      });
  };
  let owners = [];
  if (isSuccess) owners = ownersData.ownerData.map((owner) => owner);

  return (
    <>
      <div className="cars-add-btn-dv">
        <Button variant="dark" onClick={handleShow}>
          Add a car
        </Button>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>ADD CAR</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="owners">
            <Form.Select aria-label="Default select example">
              <option value="">Select Owner</option>
              {owners.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.fname} {option.lname}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <FloatingLabel controlId="plate" label="Plate" className="mb-3">
            <Form.Control type="text" placeholder="Plate" />
          </FloatingLabel>
          <FloatingLabel controlId="chassis" label="Chassis" className="mb-3">
            <Form.Control type="text" placeholder="Chassis" />
          </FloatingLabel>
          <Form.Group className="mb-3" controlId="type">
            <Form.Select aria-label="Default select example">
              <option value="">Type</option>
              <option value="COASTER">COASTER</option>
              <option value="MINI BUS">MINI BUS</option>
            </Form.Select>
          </Form.Group>
          <FloatingLabel controlId="regdate" label="Registration date">
            <Form.Control type="date" placeholder="Registration date" />
          </FloatingLabel>

          <p id="message"></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => saveCar(id, token)}>
            Save Car
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
