import { useState, useEffect } from 'react';
import axios from 'axios';
import LeftNav from '../components/Leftnav';
import TopBar from '../components/Topbar';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../css/Addacgroup.css';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useUser, useToken } from '../components/auth';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

export default function Drivers() {
  const navigate = useNavigate();
  const url = process.env.REACT_APP_BASE_URL;
  const [token] = useToken();
  const user = useUser();
  const { id } = user;
  const [searchMessage, setSearchMessage] = useState('');
  const [message, setMessage] = useState('');
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState(0);
  const [garageDataa, setGarageDataa] = useState([]);

  useEffect(() => {
    async function BankList() {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/workers/banks`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setBanks(data);
      } catch (error) {
        console.log(error);
      }
    }
    BankList();
  }, [token, url]);
  function handleBankChanges(event) {
    setSelectedBank(event.target.value);
  }

  // Getting existing users

  useEffect(() => {
    const userData = async () => {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/drivers`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setGarageDataa(data);
      } catch (error) {
        console.log(error);
      }
    };
    userData();
  }, [token, url]);

  // searching

  async function search() {
    setGarageDataa([]);
    const sdate = document.querySelector('#s-date').value;
    const edate = document.querySelector('#e-date').value;
    if (sdate === '' || edate === '') {
      setSearchMessage('Please select start date and end date');
      document.querySelector('#searchMessage').style.color = 'red';
    } else {
      setSearchMessage('Tegereza.....');
      document.querySelector('#searchMessage').style.color = 'blue';

      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/ration?sdate=${sdate}&edate=${edate}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setGarageDataa(data);
        if (data.rationsData.length !== 0) {
          setSearchMessage('');
        } else {
          setSearchMessage(`No data found from ${sdate} to ${edate}`);
          document.querySelector('#searchMessage').style.color = 'green';
        }
      } catch (error) {
        console.log(error);
        setSearchMessage('Error while retrieving data');
        document.querySelector('#searchMessage').style.color = 'red';
      }
    }
  }

  // Adding garages data

  async function saveDriver() {
    const names = document.querySelector('#names').value;
    const idnum = document.querySelector('#idnum').value;
    const phone = document.querySelector('#phone').value;
    const account = document.querySelector('#account').value;

    if (names === '' || selectedBank === 0 || account === '') {
      setMessage('Please fill out all the required fields');
      document.querySelector('#message').style.color = 'red';
    } else {
      setMessage('Tegereza.....');
      document.querySelector('#message').style.color = 'blue';
      try {
        const datass = JSON.stringify({
          names,
          idnum,
          phone,
          bank: selectedBank,
          account,
          user: id,
        });
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${url}/drivers`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          data: datass,
        };

        const { data } = await axios.request(config);
        if (data.message === 'Driver added') {
          document.querySelector('#names').value = '';
          document.querySelector('#idnum').value = '';
          document.querySelector('#phone').value = '';
          document.querySelector('#account').value = '';
          setSelectedBank(0);
          setMessage(data.message);
          document.querySelector('#message').style.color = 'green';
        }
        setMessage(data.message);
      } catch (error) {
        console.log(error);
        setMessage(`Ntibishoboye gukunda`);
        document.querySelector('#message').style.color = 'red';
      }
    }
  }

  // displaying garages datas

  return (
    <div className="App">
      <div className="left-side">
        <LeftNav />
      </div>
      <div className="right-side">
        <div>
          <TopBar page="Drivers" />
        </div>
        {/* Tab here */}
        <br />
        <Tabs
          defaultActiveKey="list"
          id="uncontrolled-tab-example"
          className="mb-2"
        >
          <Tab eventKey="list" title="Driver list">
            <div className="cars-add-btn-dv">
              <input
                name="s-date"
                id="s-date"
                placeholder="Search a driver"
                type="text"
              />

              <Button onClick={search} variant="contained">
                SEARCH
              </Button>
            </div>
            <div style={{ marginLeft: '20px' }}>
              <p id="searchMessage">{searchMessage}</p>
            </div>
            {Object.keys(garageDataa).length !== 0 && (
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead style={{ background: 'black' }}>
                    <TableRow>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>Names</h6>
                      </TableCell>

                      <TableCell>
                        <h6 style={{ color: '#fff' }}>ID</h6>
                      </TableCell>
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>Phone</h6>
                      </TableCell>

                      <TableCell>
                        <h6 style={{ color: '#fff' }}>Account</h6>
                      </TableCell>

                      <TableCell>
                        <h6 style={{ color: '#fff' }}>Bank</h6>
                      </TableCell>

                      <TableCell>
                        <h6>More</h6>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {garageDataa.driversData.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>{row.names}</TableCell>
                        <TableCell>{row.idnum}</TableCell>
                        <TableCell>{row.phone}</TableCell>
                        <TableCell>{row.account}</TableCell>
                        <TableCell>{row.bank}</TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            onClick={() =>
                              navigate('/driverdata', { state: { row } })
                            }
                            startIcon={<InfoIcon />}
                          >
                            More
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}

                    {Object.keys(garageDataa).length === 0 && (
                      <p>Loading........</p>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Tab>
          <Tab eventKey="add" title="Add a driver">
            {/*Add user here */}
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': {
                  m: 1,
                  width: '30ch',
                  marginTop: '20px',
                },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="names"
                  label="Driver names*"
                  type="text"
                  defaultValue=""
                />
                <TextField id="idnum" label="ID" type="text" defaultValue="" />
              </div>

              <div>
                <FormControl sx={{ m: 1, minWidth: 300, marginTop: 2.5 }}>
                  <InputLabel id="demo-simple-select-label">Bank*</InputLabel>
                  <Select
                    id="bank"
                    label="Bank*"
                    value={selectedBank}
                    onChange={handleBankChanges}
                  >
                    <MenuItem key={0} value={0}>
                      <span>Hitamo Bank</span>
                    </MenuItem>
                    {banks.length !== 0 &&
                      banks.bankLists.map((loc) => (
                        <MenuItem key={loc.id} value={loc.id}>
                          {loc.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <TextField
                  id="account"
                  label="Account number*"
                  type="text"
                  defaultValue=""
                />
              </div>

              <div>
                <TextField
                  id="phone"
                  label="Phone"
                  type="text"
                  defaultValue=""
                />
              </div>

              <p style={{ marginLeft: '15%' }} id="message">
                {message}
              </p>
              <div style={{ marginLeft: '20%', marginTop: '2%' }}>
                <Button variant="contained" onClick={saveDriver}>
                  Save
                </Button>
              </div>
            </Box>

            {/* End of add a user */}
          </Tab>
        </Tabs>
        {/* Tabs ends here */}
      </div>
    </div>
  );
}
