import Button from '@mui/material/Button';
import axios from 'axios';
import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useToken } from '../components/auth';
// import moment from 'moment';
import writeXlsxFile from 'write-excel-file';
import GetAppIcon from '@mui/icons-material/GetApp';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

export default function SingleDriverRation() {
  const url = process.env.REACT_APP_BASE_URL;
  const [token] = useToken();
  const [searchMessage, setSearchMessage] = useState('');
  const [tDatas, setTDatas] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [driver, setDriver] = useState(0);

  useEffect(() => {
    async function locationsList() {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/cars/drivers`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setDrivers(data);
      } catch (error) {
        console.log(error);
      }
    }
    locationsList();
  }, [token, url]);

  function handleDriverChanges(event) {
    setDriver(event.target.value);
  }

  async function search() {
    setTDatas([]);
    const sdate = document.querySelector('#s-date-d').value;
    const edate = document.querySelector('#e-date-d').value;
    console.log(edate);
    if (sdate === '' || edate === '' || driver === 0 || driver === '') {
      setSearchMessage('Please select start date, end date and the driver');
      document.querySelector('#searchMessage').style.color = 'red';
    } else {
      setSearchMessage('Tegereza.....');
      document.querySelector('#searchMessage').style.color = 'blue';

      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/report/singledriver?sdate=${sdate}&edate=${edate}&driver=${driver}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setTDatas(data);
        if (data.rationDriver.length !== 0) {
          setSearchMessage('');
        } else {
          setSearchMessage(`No data found from ${sdate} to ${edate}`);
          document.querySelector('#searchMessage').style.color = 'green';
        }
      } catch (error) {
        console.log(error);
        setSearchMessage('Error while retrieving data');
        document.querySelector('#searchMessage').style.color = 'red';
      }
    }
  }
  let total = 0;
  let displayData = [];

  if (Object.keys(tDatas).length !== 0) {
    let i = 0;
    displayData = tDatas.rationDriver.map((row) => {
      i++;
      total += parseInt(row.amount);
      const ti = new Date(row.time)
        .toLocaleString('en-GB', {
          timeZone: 'Africa/Kigali',
        })
        .substring(0, 10);

      return { ...row, time: ti, i };
    });
  }
  // preparing data for export

  const HEADER_ROW = [
    {
      value: 'No',
      fontWeight: 'bold',
    },
    {
      value: 'DATE',
      fontWeight: 'bold',
    },

    {
      value: 'Amount',
      fontWeight: 'bold',
    },
    {
      value: 'Account',
      fontWeight: 'bold',
    },
    {
      value: 'Bank',
      fontWeight: 'bold',
    },
    {
      value: 'Location',
      fontWeight: 'bold',
    },
    {
      value: 'Plate',
      fontWeight: 'bold',
    },
  ];
  //datas
  let rowss = [];
  rowss = displayData.map((row) => {
    return [
      {
        type: Number,
        value: row.i,
      },
      {
        value: row.time,
      },
      {
        value: row.amount,
      },
      {
        value: row.account,
      },
      {
        value: row.bank,
      },
      {
        value: row.location_name,
      },
      {
        value: row.plate,
      },
    ];
  });
  //totals
  const TOTAL_ROW = [
    {
      value: 'TOTAL',
      fontWeight: 'bold',
    },
    {
      value: '',
      fontWeight: 'bold',
    },
    {
      value: total,
      fontWeight: 'bold',
    },
    {
      value: '',
      fontWeight: 'bold',
    },
    {
      value: '',
      fontWeight: 'bold',
    },
    {
      value: '',
      fontWeight: 'bold',
    },
  ];

  const data = [HEADER_ROW, ...rowss, TOTAL_ROW];
  async function exportData(d) {
    await writeXlsxFile(d, {
      fileName: 'Driver-ration.xlsx',
    });
  }

  return (
    <>
      <div className="cars-add-btn-dv">
        <FormControl
          sx={{
            m: 1,
            minWidth: 300,
            marginTop: 2.5,
          }}
        >
          <InputLabel id="demo-simple-select-label">Driver*</InputLabel>
          <Select
            id="driver"
            label="Driver*"
            value={driver}
            onChange={handleDriverChanges}
          >
            <MenuItem key={0} value={0}>
              Select a driver
            </MenuItem>
            {drivers.length !== 0 &&
              drivers.driversData.map((loc) => (
                <MenuItem key={loc.id} value={loc.id}>
                  {loc.names}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <input
          style={{ height: '40px', marginTop: '30px' }}
          name="s-date-d"
          id="s-date-d"
          type="date"
        />
        <span style={{ width: '30px' }}> </span>
        <input
          style={{ height: '40px', marginTop: '30px' }}
          name="e-date-d"
          id="e-date-d"
          type="date"
        />
        <span style={{ width: '10px' }}> </span>
        <Button
          style={{ height: '40px', marginTop: '30px' }}
          variant="contained"
          onClick={search}
        >
          SEARCH
        </Button>
      </div>
      <div style={{ marginLeft: '20px' }}>
        <p id="searchMessage">{searchMessage}</p>
      </div>
      {Object.keys(tDatas).length !== 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead style={{ background: 'black' }}>
              <TableRow className="">
                <TableCell>
                  <h6 style={{ color: '#fff' }}>No</h6>
                </TableCell>
                <TableCell>
                  <h6 style={{ color: '#fff' }}>Date</h6>
                </TableCell>
                <TableCell>
                  {' '}
                  <h6 style={{ color: '#fff' }}>Amount</h6>
                </TableCell>
                <TableCell>
                  {' '}
                  <h6 style={{ color: '#fff' }}>Account</h6>
                </TableCell>
                <TableCell>
                  {' '}
                  <h6 style={{ color: '#fff' }}>Bank</h6>
                </TableCell>
                <TableCell>
                  <h6 style={{ color: '#fff' }}>Location</h6>
                </TableCell>

                <TableCell>
                  <h6 style={{ color: '#fff' }}>Plate</h6>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {displayData.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell>{row.i}</TableCell>
                  <TableCell>
                    {new Date(row.time)
                      .toLocaleString('en-GB', {
                        timeZone: 'Africa/Kigali',
                      })
                      .substring(0, 10)}
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat().format(row.amount)}
                  </TableCell>
                  <TableCell>{row.account} </TableCell>
                  <TableCell>{row.bank} </TableCell>
                  <TableCell>{row.location_name} </TableCell>
                  <TableCell>{row.plate}</TableCell>
                </TableRow>
              ))}
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell>Total</TableCell>
                <TableCell></TableCell>
                <TableCell>{new Intl.NumberFormat().format(total)}</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              {Object.keys(tDatas).length === 0 && <p>Loading........</p>}
            </TableBody>
          </Table>
          <Button
            variant="outlined"
            onClick={() => exportData(data)}
            startIcon={<GetAppIcon />}
          >
            Export data
          </Button>
        </TableContainer>
      )}
    </>
  );
}
