import * as React from 'react';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchCars } from '../redux/cars/carSlice';
import { useToken, useUser } from './auth';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function CarsByACData({ owner, sdate, edate }) {
  const [token] = useToken();
  const user = useUser();
  const { id } = user;
  const [carsData, setCarsData] = useState([]);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [carToEdit, setCarToEdit] = useState({
    plate: '',
    ligne: '',
    kivu_belt: '',
  });

  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [dataTodelete, setDataTodelete] = useState(0);

  const handleClosePopup = () => {
    setOpen(false);
  };

  const deleteAcData = (data) => {
    setOpen(true);
    setDataTodelete(data);
  };

  async function deleteData() {
    document.querySelector('#acmessage').innerHTML = '';
    document.querySelector('#acmessage').style.color = 'blue';
    try {
      document.querySelector('#acmessage').innerHTML = `Tegereza....`;
      const datass = JSON.stringify({
        acData: dataTodelete,
        user: id,
      });
      const config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${url}/acgroup/delete`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        data: datass,
      };

      const { data } = await axios.request(config);
      if (data.message === 'Removed') {
        // navigate(-1);
        document.querySelector('#acmessage').style.color = 'green';
        document.querySelector(`#id-${dataTodelete}`).innerHTML = '';
        handleClosePopup();
      }
    } catch (error) {
      console.log(error);
      document.querySelector('#acmessage').innerHTML = 'Unable to delete data';
      document.querySelector('#acmessage').style.color = 'red';
    }
  }

  useEffect(() => {
    dispatch(fetchCars(token));
  }, [dispatch, token]);
  const own = parseInt(owner);

  //geting data
  const url = process.env.REACT_APP_BASE_URL;
  //end of getting data

  useEffect(() => {
    async function getCarsData() {
      try {
        const datass = JSON.stringify({
          sdate,
          edate,
          owner: own,
        });
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${url}/acgroup/cars-data`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          data: datass,
        };

        const { data } = await axios.request(config);
        setCarsData(data);
      } catch (error) {
        console.log(error);
      }
    }
    getCarsData();
  }, [owner, sdate, edate, own, token, url]);

  function editModal(car) {
    handleShow();
    setCarToEdit(car);
    setMessage('');
  }

  function closeEdit() {
    setCarToEdit({
      plate: '',
      ligne: '',
      kivu_belt: '',
    });
    handleClose();
  }

  async function editAcData() {
    const line = document.querySelector('#line').value;
    const kivu_belt = document.querySelector('#kivu').value;
    const tickets = document.querySelector('#tickets').value;
    const amount = document.querySelector('#amount').value;

    if (line === '' || kivu_belt === '' || tickets === '' || amount === '') {
      setMessage('Please fill out all the required fields');
      document.querySelector('#message').style.color = 'red';
    } else {
      try {
        const datass = JSON.stringify({
          line,
          kivu_belt,
          amount,
          tickets,
          car: carToEdit.id,
          sdate,
          edate,
          owner: own,
        });
        const config = {
          method: 'put',
          maxBodyLength: Infinity,
          url: `${url}/acgroup`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          data: datass,
        };

        const { data } = await axios.request(config);
        if (data.message === 'Ac data edited') {
          setMessage(data.message);
          document.querySelector('#message').style.color = 'green';
          setCarsData(data);
          closeEdit();
        }
        setMessage(data.message);
      } catch (error) {
        console.log(error);
        setMessage(`Garage data could not be added`);
        document.querySelector('#message').style.color = 'red';
      }
    }
  }

  if (Object.keys(carsData).length === 0) return <p>Loading......</p>;

  return (
    <>
      {/* delete popup */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Delete Data
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this data?
          </DialogContentText>
          <p id="acmessage"></p>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClosePopup}>
            No
          </Button>
          <Button onClick={deleteData}>Yes</Button>
        </DialogActions>
      </Dialog>
      {/* End of delete popup */}
      <Modal show={show} onHide={() => closeEdit()}>
        <Modal.Header closeButton>
          <Modal.Title>{carToEdit.plate}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId="tickets" label="Tickets" className="mb-3">
            <Form.Control
              type="text"
              defaultValue={carToEdit.tickets}
              placeholder="Tickets"
            />
          </FloatingLabel>

          <FloatingLabel controlId="amount" label="Amount" className="mb-3">
            <Form.Control
              type="text"
              defaultValue={carToEdit.amount}
              placeholder="Amount"
            />
          </FloatingLabel>

          <FloatingLabel controlId="line" label="Line" className="mb-3">
            <Form.Control
              type="text"
              defaultValue={carToEdit.ligne}
              placeholder="Line"
            />
          </FloatingLabel>
          <FloatingLabel controlId="kivu" label="Kivu Belt" className="mb-3">
            <Form.Control
              type="number"
              defaultValue={carToEdit.kivu_belt}
              placeholder="Kivu Belt"
            />
          </FloatingLabel>

          <p id="message">{message}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeEdit()}>
            Close
          </Button>
          <Button variant="primary" onClick={() => editAcData()}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>

      <TableContainer
        style={{ marginLeft: '20px', marginTop: '20px' }}
        component={Paper}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Plate</StyledTableCell>
              <StyledTableCell align="right">Tickets</StyledTableCell>
              <StyledTableCell align="right">Amount</StyledTableCell>
              <StyledTableCell align="right">Ligne</StyledTableCell>
              <StyledTableCell align="right">Kivu Belt</StyledTableCell>
              <StyledTableCell align="right">Date</StyledTableCell>
              <StyledTableCell align="right">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {carsData.acDataByOwner.map((row) => (
              <StyledTableRow id={`id-${row.id}`} key={row.id}>
                <StyledTableCell component="th" scope="row">
                  {row.plate}
                </StyledTableCell>
                <StyledTableCell align="right">{row.tickets}</StyledTableCell>
                <StyledTableCell align="right">{row.amount}</StyledTableCell>
                <StyledTableCell align="right">{row.ligne}</StyledTableCell>
                <StyledTableCell align="right">{row.kivu_belt}</StyledTableCell>
                <StyledTableCell align="right">
                  {row.fromDate.substring(0, 10)} -{' '}
                  {row.toDate.substring(0, 10)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {/* <Button variant="dark" onClick={() => editModal(row)}>
                    Edit
                  </Button> */}

                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Edit
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => editModal(row)}>
                        Update AC Data
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => deleteAcData(row.id)}>
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
