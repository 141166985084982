import { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/Addacgroup.css';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCars } from '../redux/cars/carSlice';
import { useUser, useToken } from '../components/auth';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

export default function AddAbsentCars() {
  const url = process.env.REACT_APP_BASE_URL;
  const [token] = useToken();
  const user = useUser();
  const { id } = user;
  const { carData, isSuccess } = useSelector((state) => state.cars);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCars(token));
  }, [dispatch, token]);
  const [message, setMessage] = useState('');
  const [plate, setPlate] = useState(0);
  const [scar, setScar] = useState([{ id: 0, owner: 0, plate: null }]);

  // Adding garages data
  function handleChanges(event) {
    setPlate(event.target.value);
    const selectedDriver = carData.carsData.filter(
      (d) => d.id === event.target.value
    );
    if (event.target.value === '' || event.target.value === 0) {
      setScar([{ id: 0, owner: 0, plate: null }]);
    } else {
      setScar(selectedDriver);
    }
  }

  async function saveGarage() {
    const datee = document.querySelector('#date').value;
    const parking = document.querySelector('#parking').value;
    const amount = document.querySelector('#amount').value;
    setMessage('Please wait.....');
    document.querySelector('#message').style.color = 'blue';

    if (plate === 0 || plate === '0' || parking === '' || amount === '') {
      setMessage('Please fill out all the required fields');
      document.querySelector('#message').style.color = 'red';
    } else {
      try {
        const datass = JSON.stringify({
          plateId: plate,
          datee: datee,
          parking,
          amount,
          owner: scar[0].owner,
          plate: scar[0].plate,
          user: id,
        });
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${url}/acgroup/absent`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          data: datass,
        };

        const { data } = await axios.request(config);
        if (data.message === 'The car is added') {
          document.querySelector('#date').value = '';
          document.querySelector('#parking').value = 0;
          document.querySelector('#amount').value = 0;
          setPlate(0);
          setMessage(data.message);
          document.querySelector('#message').style.color = 'green';
        }
        setMessage(data.message);
      } catch (error) {
        console.log(error);
        setMessage(`The car could not be added`);
        document.querySelector('#message').style.color = 'red';
      }
    }
  }

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': {
          m: 1,
          width: '30ch',
          marginTop: '20px',
        },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <FormControl sx={{ m: 1, minWidth: 300, marginTop: 2.5 }}>
          <InputLabel id="demo-simple-select-label">Plate*</InputLabel>
          <Select
            id="plate"
            label="Plate*"
            value={plate}
            onChange={handleChanges}
          >
            <MenuItem key={0} value={0}>
              Slect a car
            </MenuItem>
            {isSuccess &&
              carData.carsData.map((car) => (
                <MenuItem key={car.id} value={car.id}>
                  {car.plate}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <TextField id="date" type="date" defaultValue="" />
      </div>
      <div>
        <TextField
          id="parking"
          label="Parking*"
          type="number"
          defaultValue="0"
        />
        <TextField id="amount" label="Amount*" type="number" defaultValue="0" />
      </div>

      <p id="message" style={{ marginLeft: '15%' }}>
        {message}
      </p>
      <div style={{ marginLeft: '20%', marginTop: '2%' }}>
        <Button variant="contained" onClick={saveGarage}>
          Save
        </Button>
      </div>
    </Box>
  );
}
