import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import '../css/Left-nav.css';
import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import EvStationIcon from '@mui/icons-material/EvStation';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import BusAlertIcon from '@mui/icons-material/BusAlert';
import LocalCarWashIcon from '@mui/icons-material/LocalCarWash';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router';
import { useUser } from '../components/auth';

export default function LeftNav() {
  const navigate = useNavigate();
  const user = useUser();

  const { fname, lname, type } = user;
  return (
    <>
      <div className="user-info">
        <div className="user-logo">
          <AccountCircleIcon style={{ color: '#fff' }} sx={{ fontSize: 100 }} />
        </div>
        <div className="u-info">
          <h3>
            {fname} {lname}
          </h3>
          <h4>{type}</h4>
        </div>
      </div>

      <ul className="nav-links">
        <li>
          <Link to="/dashboard">
            <div className="icon">
              <DashboardIcon className="icons" />
            </div>
            <h4 className="nav-name">Dashboard</h4>
          </Link>
        </li>
        {(type === 'HQ' || type === 'Admin') && (
          <li>
            <Link to="/cars">
              <div className="icon">
                <AirportShuttleIcon className="icons" />
              </div>
              <h4 className="nav-name">Cars</h4>
            </Link>
          </li>
        )}

        {(type === 'HQ' || type === 'Admin') && (
          <li>
            <Link to="/ACgroup">
              <div className="icon">
                <FormatListBulletedIcon className="icons" />
              </div>
              <h4 className="nav-name">AC Group</h4>
            </Link>
          </li>
        )}

        {(type === 'Bus stop' || type === 'HQ' || type === 'Admin') && (
          <li>
            <Link to="/bus-stops">
              <div className="icon">
                <BusAlertIcon className="icons" />
              </div>
              <h4 className="nav-name">Bus stops</h4>
            </Link>
          </li>
        )}

        {(type === 'Garage' || type === 'HQ' || type === 'Admin') && (
          <li>
            <Link to="/garage">
              <div className="icon">
                <CarRepairIcon className="icons" />
              </div>
              <h4 className="nav-name">Garage</h4>
            </Link>
          </li>
        )}

        {(type === 'Car wash' ||
          type === 'Bus stop' ||
          type === 'HQ' ||
          type === 'Admin') && (
          <li>
            <Link to="/carwash">
              <div className="icon">
                <LocalCarWashIcon className="icons" />
              </div>
              <h4 className="nav-name">Carwash</h4>
            </Link>
          </li>
        )}

        {(type === 'Fuel' ||
          type === 'Bus stop' ||
          type === 'HQ' ||
          type === 'Admin') && (
          <li>
            <Link to="/fuel">
              <div className="icon">
                <EvStationIcon className="icons" />
              </div>
              <h4 className="nav-name">Fuel</h4>
            </Link>
          </li>
        )}

        {(type === 'Bus stop' || type === 'HQ' || type === 'Admin') && (
          <li>
            <Link to="/spent">
              <div className="icon">
                <AddShoppingCartIcon className="icons" />
              </div>
              <h4 className="nav-name">Depense</h4>
            </Link>
          </li>
        )}

        {(type === 'Parcels' || type === 'HQ' || type === 'Admin') && (
          <li>
            <Link to="/parcels">
              <div className="icon">
                <LocalPostOfficeIcon className="icons" />
              </div>
              <h4 className="nav-name">Parcels</h4>
            </Link>
          </li>
        )}
        {type === 'Admin' && (
          <li>
            <Link to="/settings">
              <div className="icon">
                <SettingsIcon className="icons" />
              </div>
              <h4 className="nav-name">Settings</h4>
            </Link>
          </li>
        )}
        <li
          onClick={() => {
            localStorage.removeItem('token');
            navigate('/dashBoard');
          }}
        >
          <a href="/">
            <div className="icon">
              <LogoutIcon className="icons" />
            </div>
            <h4 className="nav-name">Logout</h4>
          </a>
        </li>
      </ul>
    </>
  );
}
