import { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/Addacgroup.css';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { useToken } from '../components/auth';
// import { Link } from 'react-router-dom';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

export default function Adduser() {
  const url = process.env.REACT_APP_BASE_URL;
  const [token] = useToken();
  const [message, setMessage] = useState('');
  const [typee, setTypee] = useState('');
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState('');
  // Getting existing users
  useEffect(() => {
    async function locationsList() {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/signup/locations`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setLocations(data);
      } catch (error) {
        console.log(error);
      }
    }
    locationsList();
  }, [token, url]);

  function handleLocationChanges(event) {
    setLocation(event.target.value);
  }

  // Adding a new user
  function handleChanges(event) {
    setTypee(event.target.value);
  }
  async function addUser() {
    const fname = document.querySelector('#fname').value;
    const lname = document.querySelector('#lname').value;
    const username = document.querySelector('#username').value;
    const password = document.querySelector('#password').value;
    const phone = document.querySelector('#phone').value;
    const type = typee;

    if (
      fname === '' ||
      lname === '' ||
      username === '' ||
      password === '' ||
      type === '' ||
      location === ''
    ) {
      setMessage('Please fill out all the required fields');
      document.querySelector('#message').style.color = 'red';
    } else {
      setMessage('Please wait......');
      document.querySelector('#message').style.color = 'blue';
      try {
        const datass = JSON.stringify({
          fname,
          lname,
          phone,
          username,
          password,
          type,
          location,
        });
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${url}/signup`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          data: datass,
        };

        const { data } = await axios.request(config);
        if (data.message === 'A new has been created successfully!') {
          document.querySelector('#fname').value = '';
          document.querySelector('#lname').value = '';
          document.querySelector('#username').value = '';
          document.querySelector('#password').value = '';
          document.querySelector('#phone').value = '';
          document.querySelector('#type').value = '';
          document.querySelector('#location').value = '';
          setMessage(data.message);
          setLocation('');
          setTypee('');
          document.querySelector('#message').style.color = 'green';
        }
        setMessage(data.message);
      } catch (error) {
        console.log(error);
        setMessage(`User could not be added`);
        document.querySelector('#message').style.color = 'red';
      }
    }
  }

  // displaying users

  return (
    <>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': {
            m: 1,
            width: '30ch',
            marginTop: '20px',
          },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField id="fname" label="First name*" defaultValue="" />
          <TextField id="lname" label="Last Name*" defaultValue="" />
        </div>
        <div>
          <TextField
            id="username"
            label="Username*"
            defaultValue=""
            variant="filled"
          />
          <TextField
            id="password"
            label="Password*"
            variant="filled"
            type="password"
          />
        </div>
        <div>
          <TextField id="phone" label="Phone" defaultValue="" />
          <TextField
            id="type"
            select
            label="Select*"
            value={typee}
            helperText="Please select user type"
            onChange={handleChanges}
          >
            <MenuItem value="Admin">Admin</MenuItem>
            <MenuItem value="HQ">HQ</MenuItem>
            <MenuItem value="Garage">Garage</MenuItem>
            <MenuItem value="Bus stop">Bus stop</MenuItem>
            <MenuItem value="Car wash">Car wash</MenuItem>
            <MenuItem value="Fuel">Fuel</MenuItem>
            <MenuItem value="Parcels">Parcels</MenuItem>
          </TextField>
        </div>
        <div>
          <FormControl sx={{ m: 1, minWidth: 600, marginTop: 2.5 }}>
            <InputLabel id="demo-simple-select-label">Location*</InputLabel>
            <Select
              id="location"
              label="Location*"
              value={location}
              onChange={handleLocationChanges}
            >
              {locations.length !== 0 &&
                locations.locationLists.map((loc) => (
                  <MenuItem key={loc.id} value={loc.id}>
                    {loc.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <p style={{ marginLeft: '15%' }} id="message">
          {message}
        </p>
        <div style={{ marginLeft: '20%', marginTop: '2%' }}>
          <Button variant="contained" onClick={addUser}>
            Save
          </Button>
        </div>
      </Box>
    </>
  );
}
