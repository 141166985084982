import { useState, useEffect } from 'react';
import axios from 'axios';
import LeftNav from '../components/Leftnav';
import TopBar from '../components/Topbar';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../css/Addacgroup.css';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useToken } from '../components/auth';
// import { Link } from 'react-router-dom';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router';

export default function Users() {
  const navigate = useNavigate();
  const url = process.env.REACT_APP_BASE_URL;
  const [token] = useToken();
  const [message, setMessage] = useState('');
  const [typee, setTypee] = useState('');
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState('');
  // Getting existing users
  const [userDataa, setUserDataa] = useState('');

  useEffect(() => {
    async function locationsList() {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/signup/locations`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setLocations(data);
      } catch (error) {
        console.log(error);
      }
    }
    locationsList();
  }, [token, url]);

  function handleLocationChanges(event) {
    setLocation(event.target.value);
  }

  useEffect(() => {
    const userData = async () => {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/signup`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setUserDataa(data);
      } catch (error) {
        console.log(error);
      }
    };
    userData();
  }, [token, url]);
  // Adding a new user
  function handleChanges(event) {
    setTypee(event.target.value);
  }
  async function addUser() {
    const fname = document.querySelector('#fname').value;
    const lname = document.querySelector('#lname').value;
    const username = document.querySelector('#username').value;
    const password = document.querySelector('#password').value;
    const phone = document.querySelector('#phone').value;
    const type = typee;

    if (
      fname === '' ||
      lname === '' ||
      username === '' ||
      password === '' ||
      type === '' ||
      location === ''
    ) {
      setMessage('Please fill out all the required fields');
      document.querySelector('#message').style.color = 'red';
    } else {
      setMessage('Please wait......');
      document.querySelector('#message').style.color = 'blue';
      try {
        const datass = JSON.stringify({
          fname,
          lname,
          phone,
          username,
          password,
          type,
          location,
        });
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${url}/signup`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          data: datass,
        };

        const { data } = await axios.request(config);
        if (data.message === 'A new has been created successfully!') {
          document.querySelector('#fname').value = '';
          document.querySelector('#lname').value = '';
          document.querySelector('#username').value = '';
          document.querySelector('#password').value = '';
          document.querySelector('#phone').value = '';
          document.querySelector('#type').value = '';
          document.querySelector('#location').value = '';
          setMessage(data.message);
          setLocation('');
          setTypee('');
          document.querySelector('#message').style.color = 'green';
        }
        setMessage(data.message);
      } catch (error) {
        console.log(error);
        setMessage(`User could not be added`);
        document.querySelector('#message').style.color = 'red';
      }
    }
  }

  // displaying users

  return (
    <div className="App">
      <div className="left-side">
        <LeftNav />
      </div>
      <div className="right-side">
        <div>
          <TopBar page="Users" />
        </div>
        {/* Tab here */}
        <br />
        <Tabs
          defaultActiveKey="users"
          id="uncontrolled-tab-example"
          className="mb-2"
        >
          <Tab eventKey="users" title="Users">
            {Object.keys(userDataa).length !== 0 && (
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead style={{ background: 'black' }}>
                    <TableRow className="">
                      <TableCell>
                        <h6 style={{ color: '#fff' }}>Names</h6>
                      </TableCell>
                      <TableCell align="right">
                        {' '}
                        <h6 style={{ color: '#fff' }}>Useraname</h6>
                      </TableCell>
                      <TableCell align="right">
                        <h6 style={{ color: '#fff' }}>Phone</h6>
                      </TableCell>
                      <TableCell align="right">
                        <h6 style={{ color: '#fff' }}>Type</h6>
                      </TableCell>
                      <TableCell align="right">
                        <h6 style={{ color: '#fff' }}>Location</h6>
                      </TableCell>
                      <TableCell align="right">
                        <h6 style={{ color: '#fff' }}>Status</h6>
                      </TableCell>
                      <TableCell align="right">
                        <h6 style={{ color: '#fff' }}>More</h6>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {userDataa.getUser.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.fname} {row.lname}
                        </TableCell>
                        <TableCell align="right">{row.username}</TableCell>
                        <TableCell align="right">{row.phone}</TableCell>
                        <TableCell align="right">{row.type}</TableCell>
                        <TableCell align="right">{row.location_name}</TableCell>
                        <TableCell align="right">
                          {row.active === '1' ? 'Active' : 'Inactive'}
                        </TableCell>
                        <TableCell align="right">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              Edit
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() =>
                                  navigate('/update-profile', {
                                    state: { row },
                                  })
                                }
                              >
                                Update profile
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  navigate('/new-password', { state: { row } })
                                }
                              >
                                Change password
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </TableCell>
                      </TableRow>
                    ))}
                    {Object.keys(userDataa).length === 0 && (
                      <p>Loading........</p>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Tab>
          <Tab eventKey="add" title="Add a user">
            {/*Add user here */}
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': {
                  m: 1,
                  width: '30ch',
                  marginTop: '20px',
                },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField id="fname" label="First name*" defaultValue="" />
                <TextField id="lname" label="Last Name*" defaultValue="" />
              </div>
              <div>
                <TextField
                  id="username"
                  label="Username*"
                  defaultValue=""
                  variant="filled"
                />
                <TextField
                  id="password"
                  label="Password*"
                  variant="filled"
                  type="password"
                />
              </div>
              <div>
                <TextField id="phone" label="Phone" defaultValue="" />
                <TextField
                  id="type"
                  select
                  label="Select*"
                  value={typee}
                  helperText="Please select user type"
                  onChange={handleChanges}
                >
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="HQ">HQ</MenuItem>
                  <MenuItem value="Garage">Garage</MenuItem>
                  <MenuItem value="Bus stop">Bus stop</MenuItem>
                  <MenuItem value="Car wash">Car wash</MenuItem>
                  <MenuItem value="Fuel">Fuel</MenuItem>
                  <MenuItem value="Parcels">Parcels</MenuItem>
                </TextField>
              </div>
              <div>
                <FormControl sx={{ m: 1, minWidth: 600, marginTop: 2.5 }}>
                  <InputLabel id="demo-simple-select-label">
                    Location*
                  </InputLabel>
                  <Select
                    id="location"
                    label="Location*"
                    value={location}
                    onChange={handleLocationChanges}
                  >
                    {locations.length !== 0 &&
                      locations.locationLists.map((loc) => (
                        <MenuItem key={loc.id} value={loc.id}>
                          {loc.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <p style={{ marginLeft: '15%' }} id="message">
                {message}
              </p>
              <div style={{ marginLeft: '20%', marginTop: '2%' }}>
                <Button variant="contained" onClick={addUser}>
                  Save
                </Button>
              </div>
            </Box>

            {/* End of add a user */}
          </Tab>
        </Tabs>
        {/* Tabs ends here */}
      </div>
    </div>
  );
}
