import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { useUser, useToken } from '../components/auth';
import { useDispatch } from 'react-redux';
import { addOwner } from '../redux/cars/ownerSlice';

export default function AddOwner() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  //const { message } = useSelector((state) => state.owners);

  const user = useUser();
  const [token] = useToken();
  const { id } = user;
  const saveOwner = (id, token) => {
    const fname = document.querySelector('#fname').value;
    const lname = document.querySelector('#lname').value;
    const idnum = document.querySelector('#idnum').value;
    const phone = document.querySelector('#phone').value;
    const bank = document.querySelector('#bank').value;
    const account = document.querySelector('#account').value;
    const reqBody = {
      datas: { fname, lname, id: idnum, phone, bank, account, user: id },
      token,
    };

    dispatch(addOwner(reqBody))
      .then((response) => {
        if (response.payload.message === 'owner added') handleClose();
        document.querySelector('#message').innerHTML = response.payload.message;
      })
      .catch((error) => {
        document.querySelector('#message').innerHTML = error;
      });
  };

  return (
    <>
      <div className="cars-add-btn-dv">
        <Button variant="dark" onClick={handleShow}>
          Add Owner
        </Button>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>ADD CAR OWNER</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId="fname" label="First Name" className="mb-3">
            <Form.Control type="text" placeholder="First Name" />
          </FloatingLabel>

          <FloatingLabel controlId="lname" label="Last Name" className="mb-3">
            <Form.Control type="text" placeholder="Last Name" />
          </FloatingLabel>

          <FloatingLabel controlId="idnum" label="ID" className="mb-3">
            <Form.Control type="text" placeholder="ID" />
          </FloatingLabel>
          <FloatingLabel controlId="phone" label="Phone" className="mb-3">
            <Form.Control type="text" placeholder="Phone" />
          </FloatingLabel>
          <Form.Group className="mb-3" controlId="bank">
            <Form.Select aria-label="Default select example">
              <option value="">--BANK--</option>
              <option value="AB Bank">AB Bank</option>
              <option value="Access Bank">Access Bank</option>
              <option value="BK">BK</option>
              <option value="COGEBANK">COGEBANK</option>
              <option value="Equity">Equity</option>
              <option value="I&M">I&M</option>
            </Form.Select>
          </Form.Group>
          <FloatingLabel controlId="account" label="Account Number">
            <Form.Control type="text" placeholder="Account Number" />
          </FloatingLabel>
          <br />
          <p style={{ color: 'green' }} id="message"></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => saveOwner(id, token)}>
            Save Owner
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
