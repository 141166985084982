import { useParams } from 'react-router-dom';
import LeftNav from '../components/Leftnav';
import TopBar from '../components/Topbar';
import '../css/Cars.css';
import { DisplayOwner } from '../components/Displayowner';

export default function OwnersPage() {
  const { owner } = useParams();

  return (
    <div className="App">
      <div className="left-side">
        <LeftNav />
      </div>
      <div className="right-side">
        <div>
          <TopBar page="cars" />
        </div>
        <DisplayOwner owner={owner} />
      </div>
    </div>
  );
}
