import LeftNav from '../components/Leftnav';
import TopBar from '../components/Topbar';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../css/Addacgroup.css';
import { useUser } from '../components/auth';
import UsersDisplay from '../components/UserDisplay';
import Adduser from '../components/AddUser';
import CloseReport from '../components/CloseReport';

export default function Settings() {
  const user = useUser();
  const { id, type } = user;
  // Getting existing users
  const defaultTab = type === 'users' ? 'reporting' : 'users';

  return (
    <div className="App">
      <div className="left-side">
        <LeftNav />
      </div>
      <div className="right-side">
        <div>
          <TopBar page="Settings" />
        </div>
        {/* Tab here */}
        <br />

        <Tabs
          defaultActiveKey={defaultTab}
          id="uncontrolled-tab-example"
          className="mb-2"
        >
          <Tab eventKey="users" title="Users">
            <Adduser />
            <hr />
            <UsersDisplay />
          </Tab>
          <Tab eventKey="reporting" title="Close Report">
            {(id === 14 || id === 3 || type === 'Admin') && <CloseReport />}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
