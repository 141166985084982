import LeftNav from '../components/Leftnav';
import { useState, useEffect } from 'react';
import axios from 'axios';
import TopBar from '../components/Topbar';
import '../css/Cars.css';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useUser, useToken } from '../components/auth';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditCar from '../components/EditCar';

export default function CarInfo() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [dataToUpdate, setDataToUpdate] = useState(state.row);
  const url = process.env.REACT_APP_BASE_URL;
  const [token] = useToken();
  const user = useUser();
  const { id } = user;
  const [drivers, setDrivers] = useState([]);
  const [driver, setDriver] = useState(dataToUpdate.driver);
  const [sdriver, seSdriver] = useState([
    { id: dataToUpdate.id, names: dataToUpdate.driverName },
  ]);
  const [mesage, setMessage] = useState('');
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const openPopup = () => {
    setOpen(true);
  };

  //console.log(dataToUpdate);

  async function deleteData() {
    document.querySelector('#deleteMessage').innerHTML = '';
    document.querySelector('#deleteMessage').style.color = 'blue';
    try {
      document.querySelector('#deleteMessage').innerHTML = 'Tegereza....';
      const datass = JSON.stringify({
        car: state.row.id,
        user: id,
      });
      const config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${url}/cars/delete`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        data: datass,
      };

      const { data } = await axios.request(config);
      if (data.message === 'Removed') {
        navigate(-1);
        document.querySelector('#deleteMessage').style.color = 'green';
      }
    } catch (error) {
      console.log(error);
      document.querySelector('#deleteMessage').innerHTML =
        'Unable to delete this car';
      document.querySelector('#deleteMessage').style.color = 'red';
    }
  }

  useEffect(() => {
    async function locationsList() {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/cars/drivers`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setDrivers(data);
      } catch (error) {
        console.log(error);
      }
    }
    locationsList();
  }, [token, url]);

  function handleLocationChanges(event) {
    setDriver(event.target.value);
    const selectedDriver = drivers.driversData.filter(
      (d) => d.id === event.target.value
    );
    if (event.target.value === '' || event.target.value === 0) {
      seSdriver([{ id: 0, names: null }]);
    } else {
      seSdriver(selectedDriver);
    }
  }

  //updating a user

  async function updateUser() {
    // if (driver === '' || driver === 0) {
    //   document.querySelector('#message').style.color = 'red';
    //   document.querySelector('#message').innerHTML =
    //     'Please fill out all the required fields';
    // } else {
    document.querySelector('#message').style.color = 'blue';
    document.querySelector('#message').innerHTML = 'Tegereza......';
    try {
      const datass = JSON.stringify({
        driver,
        carid: dataToUpdate.id,
        driverName: sdriver[0].names,
      });
      const config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${url}/cars/changedriver`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        data: datass,
      };

      const { data } = await axios.request(config);
      if (data.message === 'Updated successfully!') {
        document.querySelector('#message').style.color = 'green';
        setMessage(data.message);
        setDataToUpdate({
          ...dataToUpdate,
          driver,
          driverName: sdriver[0].names,
        });
      }
      document.querySelector('#message').style.color = 'red';
      document.querySelector('#message').innerHTML = data.message;
    } catch (error) {
      console.log(error);
      document.querySelector('#message').style.color = 'red';
      document.querySelector(
        '#message'
      ).innerHTML = `Driver could not be assigned`;
    }
    //}
  }

  const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));
  return (
    <div className="App">
      <div className="left-side">
        <LeftNav />
      </div>
      <div className="right-side">
        <div>
          <TopBar page="Depense" />
        </div>
        {/* displaying here */}
        <Box key={dataToUpdate.id} sx={{ flexGrow: 1, maxWidth: '90%' }}>
          <Typography
            style={{ display: 'flex', justifyContent: 'center' }}
            sx={{ mt: 4, mb: 2 }}
            variant="h4"
            component="div"
          >
            {dataToUpdate.plate}
          </Typography>
          <Grid container spacing={2}>
            <Grid
              style={{ display: 'flex', justifyContent: 'center' }}
              item
              xs={12}
              md={6}
            >
              <Demo>
                {/* delete popup */}
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="draggable-dialog-title"
                >
                  <DialogTitle
                    style={{ cursor: 'move' }}
                    id="draggable-dialog-title"
                  >
                    Delete Data
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Are you sure you want to delete this car?
                    </DialogContentText>
                    <p id="deleteMessage"></p>
                  </DialogContent>
                  <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                      No
                    </Button>
                    <Button onClick={deleteData}>Yes</Button>
                  </DialogActions>
                </Dialog>
                {/* End of delete popup */}
                <List dense={true}>
                  {/* <ListItem>
                    <ListItemText
                      primary={`Owner of the car: ${state.fname} ${state.lname}`}
                    />
                  </ListItem> */}
                  <ListItem>
                    <ListItemText primary={`Type: ${dataToUpdate.type}`} />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={`Chassis: ${dataToUpdate.chassis}`}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemText
                      primary={`Owner: ${dataToUpdate.ownerName}`}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemText
                      primary={`Driver: ${dataToUpdate.driverName}`}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemText
                      primary={`Date: ${new Date(dataToUpdate.time)
                        .toLocaleString('en-GB', {
                          timeZone: 'Africa/Kigali',
                        })
                        .substring(0, 10)}`}
                    />
                  </ListItem>
                </List>
              </Demo>
            </Grid>
            <Grid item xs={12} md={6}>
              <Demo>
                {/* begining  */}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    margin: '20px',
                  }}
                >
                  <div>
                    <FormControl
                      sx={{
                        m: 1,
                        minWidth: 200,
                        marginTop: 2.5,
                        marginLeft: 0,
                        marginRight: 0,
                      }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Change driver
                      </InputLabel>
                      <Select
                        id="driver"
                        label="Drivers*"
                        value={driver}
                        onChange={handleLocationChanges}
                      >
                        <MenuItem style={{ color: 'red' }} key={0} value={0}>
                          Unassign driver
                        </MenuItem>
                        {drivers.length !== 0 &&
                          drivers.driversData.map((loc) => (
                            <MenuItem key={loc.id} value={loc.id}>
                              {loc.names}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                  <p style={{ marginLeft: '15%' }} id="message">
                    {mesage}
                  </p>
                  {/* before end */}
                  {/* Ending */}

                  <Button
                    style={{ width: '200px' }}
                    variant="outlined"
                    startIcon={<CreateOutlinedIcon />}
                    onClick={updateUser}
                  >
                    Save
                  </Button>
                </div>
                <List dense={true}>
                  <ListItem>
                    <Button
                      variant="outlined"
                      onClick={() => navigate(-1)}
                      startIcon={<ArrowBackIosIcon />}
                      style={{ marginRight: 30 }}
                    >
                      Back
                    </Button>

                    {(id === 11 || id === 14 || id === 13 || id === 3) && (
                      <>
                        <EditCar data={dataToUpdate} />

                        <Button
                          style={{ color: 'red' }}
                          variant="outlined"
                          startIcon={<DeleteIcon />}
                          onClick={openPopup}
                        >
                          Delete car
                        </Button>
                      </>
                    )}
                  </ListItem>
                </List>
              </Demo>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}
