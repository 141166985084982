import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function Aclist({ datas }) {
  if (Object.keys(datas).length === 0) return <h4>No data</h4>;
  //console.log(datas);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Owner</StyledTableCell>
            <StyledTableCell align="right">Cars</StyledTableCell>
            <StyledTableCell align="right">More info</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {datas.acData.map((row) => (
            <StyledTableRow key={row.fname}>
              <StyledTableCell component="th" scope="row">
                <Link to={`/owners/${row.ownerId}`}>
                  {row.fname} {row.lname}
                </Link>
              </StyledTableCell>
              <StyledTableCell align="right">
                {row.numberOfCars}
              </StyledTableCell>
              <StyledTableCell align="right">
                {' '}
                <Link
                  to={`/data-by-ac/${row.ownerId}/${row.fromDate}/${row.toDate}`}
                >
                  View
                </Link>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
