import LeftNav from '../components/Leftnav';
import { useState, useEffect } from 'react';
import axios from 'axios';
import TopBar from '../components/Topbar';
import '../css/Cars.css';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useToken } from '../components/auth';

export default function UpdateProfileById() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [dataToUpdate, setDataToUpdate] = useState(state.row);
  const url = process.env.REACT_APP_BASE_URL;
  const [token] = useToken();
  const [message, setMessage] = useState('');
  const [typee, setTypee] = useState(dataToUpdate.type);
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState(dataToUpdate.location);
  const [status, setStatus] = useState(dataToUpdate.active);
  useEffect(() => {
    async function locationsList() {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/signup/locations`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setLocations(data);
      } catch (error) {
        console.log(error);
      }
    }
    locationsList();
  }, [token, url]);

  function handleLocationChanges(event) {
    setLocation(event.target.value);
  }

  function handleChanges(event) {
    setTypee(event.target.value);
  }
  function handleSatusChanges(event) {
    setStatus(event.target.value);
  }
  //updating a user

  async function updateUser() {
    const fname = document.querySelector('#fname').value;
    const lname = document.querySelector('#lname').value;
    const phone = document.querySelector('#phone').value;
    const type = typee;

    if (fname === '' || lname === '' || type === '' || location === '') {
      document.querySelector('#message').style.color = 'red';
      setMessage('Please fill out all the required fields');
    } else {
      document.querySelector('#message').style.color = 'blue';
      setMessage('Tegereza......');
      try {
        const datass = JSON.stringify({
          fname,
          lname,
          phone,
          type,
          status,
          location,
          userId: dataToUpdate.id,
        });
        const config = {
          method: 'put',
          maxBodyLength: Infinity,
          url: `${url}/signup`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          data: datass,
        };

        const { data } = await axios.request(config);
        if (data.message === 'Updated successfully!') {
          setDataToUpdate({
            ...dataToUpdate,
            fname,
            lname,
            phone,
            type,
            location,
            active: status,
          });
          document.querySelector('#message').style.color = 'green';
          setMessage(data.message);
        }
        document.querySelector('#message').style.color = 'red';
        setMessage(data.message);
      } catch (error) {
        console.log(error);
        document.querySelector('#message').style.color = 'red';
        setMessage(`User could not be updated`);
      }
    }
  }

  const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));
  return (
    <div className="App">
      <div className="left-side">
        <LeftNav />
      </div>
      <div className="right-side">
        <div>
          <TopBar page="Depense" />
        </div>
        {/* displaying here */}
        <Box key={dataToUpdate.id} sx={{ flexGrow: 1, maxWidth: '90%' }}>
          <Typography
            style={{ display: 'flex', justifyContent: 'center' }}
            sx={{ mt: 4, mb: 2 }}
            variant="h4"
            component="div"
          >
            {dataToUpdate.fname} {dataToUpdate.lname}
          </Typography>
          <Grid container spacing={2}>
            <Grid
              style={{ display: 'flex', justifyContent: 'center' }}
              item
              xs={12}
              md={6}
            >
              <Demo>
                <List dense={true}>
                  {/* <ListItem>
                    <ListItemText
                      primary={`Owner of the car: ${state.fname} ${state.lname}`}
                    />
                  </ListItem> */}
                  <ListItem>
                    <ListItemText
                      primary={`Username: ${dataToUpdate.username}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`Type: ${dataToUpdate.type}`} />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={`Location: ${dataToUpdate.location_name}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`Phone: ${dataToUpdate.phone}`} />
                  </ListItem>

                  <ListItem>
                    <ListItemText
                      primary={`Status: ${
                        dataToUpdate.active === '1' ? 'Active' : 'Inactive'
                      }`}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemText
                      primary={`Date: ${new Date(dataToUpdate.time)
                        .toLocaleString('en-GB', {
                          timeZone: 'Africa/Kigali',
                        })
                        .substring(0, 10)}`}
                    />
                  </ListItem>
                </List>
              </Demo>
            </Grid>
            <Grid item xs={12} md={6}>
              <Demo>
                {/* begining  */}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    margin: '20px',
                  }}
                >
                  <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <TextField
                      id="fname"
                      label="First name*"
                      defaultValue={dataToUpdate.fname}
                    />
                    <TextField
                      style={{ marginLeft: '20px' }}
                      id="lname"
                      label="Last Name*"
                      defaultValue={dataToUpdate.lname}
                    />
                  </div>

                  <div>
                    <TextField
                      id="phone"
                      label="Phone"
                      defaultValue={dataToUpdate.phone}
                    />
                    <TextField
                      style={{ marginLeft: '20px', minWidth: '200px' }}
                      id="type"
                      select
                      label="User type*"
                      value={typee}
                      //   helperText="Please select user type"
                      onChange={handleChanges}
                    >
                      <MenuItem value="Admin">Admin</MenuItem>
                      <MenuItem value="HQ">HQ</MenuItem>
                      <MenuItem value="Garage">Garage</MenuItem>
                      <MenuItem value="Bus stop">Bus stop</MenuItem>
                      <MenuItem value="Car wash">Car wash</MenuItem>
                      <MenuItem value="Fuel">Fuel</MenuItem>
                      <MenuItem value="Parcels">Parcels</MenuItem>
                    </TextField>
                  </div>
                  <div>
                    <FormControl
                      sx={{
                        m: 1,
                        minWidth: 200,
                        marginTop: 2.5,
                        marginLeft: 0,
                        marginRight: 0,
                      }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Location*
                      </InputLabel>
                      <Select
                        id="location"
                        label="Location*"
                        value={location}
                        onChange={handleLocationChanges}
                      >
                        {locations.length !== 0 &&
                          locations.locationLists.map((loc) => (
                            <MenuItem key={loc.id} value={loc.id}>
                              {loc.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>

                    <TextField
                      style={{
                        marginLeft: '20px',
                        marginTop: '20px',
                        minWidth: '200px',
                      }}
                      id="status"
                      select
                      label="Status*"
                      value={status}
                      //   helperText="Please select user type"
                      onChange={handleSatusChanges}
                    >
                      <MenuItem value="1">Active</MenuItem>
                      <MenuItem value="0">Inactive</MenuItem>
                    </TextField>
                  </div>
                  <p style={{ marginLeft: '15%' }} id="message">
                    {message}
                  </p>

                  {/* Ending */}

                  <Button
                    style={{ width: '200px' }}
                    variant="outlined"
                    startIcon={<CreateOutlinedIcon />}
                    onClick={updateUser}
                  >
                    Edit
                  </Button>
                </div>
                <List dense={true}>
                  <ListItem>
                    <Button
                      variant="outlined"
                      onClick={() => navigate(-1)}
                      startIcon={<ArrowBackIosIcon />}
                    >
                      Back
                    </Button>
                  </ListItem>
                </List>
              </Demo>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}
