import {
  createSlice,
  createAsyncThunk,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_BASE_URL;
export const fetchCars = createAsyncThunk('Cars list', async (token) => {
  try {
    const config = {
      method: 'get',
      url: `${url}/cars`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.request(config);
    return data;
  } catch (error) {
    return isRejectedWithValue(error.response.data);
  }
});

export const removeCar = createAsyncThunk('delete car', async (bookId) => {
  try {
    await axios.delete(`${url}/${bookId}`);
    return bookId;
  } catch (error) {
    return error.response.data;
  }
});

export const addCar = createAsyncThunk('Add car', async (newCar) => {
  try {
    const datas = JSON.stringify(newCar.datas);
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${url}/cars`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + newCar.token,
      },
      data: datas,
    };
    const { data } = await axios.request(config);
    return data;
  } catch (error) {
    return error.response.data;
  }
});

export const editCar = createAsyncThunk('Edit car', async (newCar) => {
  try {
    const datas = JSON.stringify(newCar.datas);
    const config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${url}/cars`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + newCar.token,
      },
      data: datas,
    };
    const { data } = await axios.request(config);
    return data;
  } catch (error) {
    return error.response.data;
  }
});

const initialState = {
  carData: [],
  isSuccess: false,
  message: '',
  loading: false,
};

export const carSlice = createSlice({
  name: 'cars',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchCars.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCars.fulfilled, (state, action) => {
        state.loading = false;
        state.carData = action.payload;
        state.isSuccess = true;
      })
      .addCase(fetchCars.rejected, (state, action) => {
        state.message = action.payload;
        state.loading = false;
        state.isSuccess = false;
      })
      .addCase(addCar.pending, (state) => {
        state.message = 'Please wait .....';
      })
      .addCase(addCar.fulfilled, (state, action) => {
        state.message = action.payload.message;
        if (action.payload.message === 'Car added') {
          state.carData.carsData.push(action.payload.car);
        }
      })
      .addCase(removeCar.fulfilled, (state, action) => {
        state.carData.carsData = state.carData.carsData.filter(
          (element) => element.item_id !== action.payload
        );
      });
  },
});
//export const { add, remove } = carSlice.actions;
export default carSlice.reducer;
