import { useState, useEffect } from 'react';

export const useToken = () => {
  const [token, setTokenInternal] = useState(() =>
    localStorage.getItem('token')
  );

  const setToken = (newToken) => {
    localStorage.setItem('token', newToken);
    setTokenInternal(newToken);
  };
  return [token, setToken];
};

export const useUser = () => {
  const [token] = useToken();
  const getPayloadFromToken = (token) => {
    const encodedPayload = token.split('.')[1];
    return JSON.parse(atob(encodedPayload));
  };

  const [user, setUser] = useState(() => {
    if (!token) return null;
    return getPayloadFromToken(token);
  });

  useEffect(() => {
    if (!token) {
      setUser(null);
    } else {
      setUser(getPayloadFromToken(token));
    }
  }, [token]);
  return user;
};
