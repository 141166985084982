import {
  createSlice,
  createAsyncThunk,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_BASE_URL;
export const fetchData = createAsyncThunk('owners list', async (token) => {
  try {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}/owner`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.request(config);
    return data;
  } catch (error) {
    return isRejectedWithValue(error.response.data);
  }
});

export const removeOwner = createAsyncThunk('delete owner', async (bookId) => {
  try {
    await axios.delete(`${url}/${bookId}`);
    return bookId;
  } catch (error) {
    return error.response.data;
  }
});

export const addOwner = createAsyncThunk('Add owner', async (newOwner) => {
  try {
    const datas = JSON.stringify(newOwner.datas);
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/owner`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + newOwner.token,
      },
      data: datas,
    };
    const { data } = await axios.request(config);
    return data;
  } catch (error) {
    return error.response.data;
  }
});

const initialState = {
  ownersData: [],
  isSuccess: false,
  message: '',
  loading: false,
};

export const ownerSlice = createSlice({
  name: 'owners',
  initialState,
  reducers: {
    getOwners: (state, action) => {
      const itemId = `item${state.ownersData.length + 1}`;
      state.ownersData[state.ownersData.length] = {
        item_id: itemId,
        ...action.payload,
      };
    },
    remove: (state, action) => {
      state.ownersData = state.ownersData.filter(
        (element) => element.item_id !== action.payload
      );
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.loading = false;
        state.ownersData = action.payload;
        state.isSuccess = true;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.message = action.payload;
        state.loading = false;
        state.isSuccess = false;
      })
      .addCase(addOwner.pending, (state) => {
        state.message = 'Please wait .....';
      })
      .addCase(addOwner.fulfilled, (state, action) => {
        state.message = action.payload.message;
        if (action.payload.message === 'owner added') {
          state.ownersData.ownerData.push(action.payload.owner);
        }
      })
      .addCase(removeOwner.fulfilled, (state, action) => {
        state.ownersData = state.ownersData.filter(
          (element) => element.item_id !== action.payload
        );
      });
  },
});
export const { getOwners, remove } = ownerSlice.actions;
export default ownerSlice.reducer;
