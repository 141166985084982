import { useParams } from 'react-router-dom';
import LeftNav from '../components/Leftnav';
import TopBar from '../components/Topbar';
import '../css/Cars.css';
import { DisplayAc } from '../components/DisplayAcData';

export default function Acdatadispl() {
  const { owner, sdate, edate } = useParams();

  return (
    <div className="App">
      <div className="left-side">
        <LeftNav />
      </div>
      <div className="right-side">
        <div>
          <TopBar page="AC Group" />
        </div>
        <DisplayAc owner={owner} sdate={sdate} edate={edate} />
      </div>
    </div>
  );
}
