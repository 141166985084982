import LeftNav from '../components/Leftnav';
import { useState } from 'react';
import axios from 'axios';
import TopBar from '../components/Topbar';
import '../css/Cars.css';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TextField from '@mui/material/TextField';
import { useToken } from '../components/auth';

export default function ChangePasswordById() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dataToUpdate = state.row;
  const url = process.env.REACT_APP_BASE_URL;
  const [token] = useToken();
  const [message, setMessage] = useState('');

  //updating a user

  async function updateUser() {
    const password = document.querySelector('#password').value;

    if (password === '') {
      document.querySelector('#message').style.color = 'red';
      setMessage('Please fill in new password');
    } else {
      document.querySelector('#message').style.color = 'blue';
      setMessage('Tegereza......');
      try {
        const datass = JSON.stringify({
          password,
          userId: dataToUpdate.id,
        });
        const config = {
          method: 'put',
          maxBodyLength: Infinity,
          url: `${url}/signup/password`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          data: datass,
        };

        const { data } = await axios.request(config);
        if (data.message === 'Updated successfully!') {
          document.querySelector('#message').style.color = 'green';
          setMessage(data.message);
        }
        document.querySelector('#message').style.color = 'red';
        setMessage(data.message);
      } catch (error) {
        console.log(error);
        document.querySelector('#message').style.color = 'red';
        setMessage(`Password could not be changed`);
      }
    }
  }

  const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));
  return (
    <div className="App">
      <div className="left-side">
        <LeftNav />
      </div>
      <div className="right-side">
        <div>
          <TopBar page="Depense" />
        </div>
        {/* displaying here */}
        <Box key={dataToUpdate.id} sx={{ flexGrow: 1, maxWidth: '90%' }}>
          <Typography
            style={{ display: 'flex', justifyContent: 'center' }}
            sx={{ mt: 4, mb: 2 }}
            variant="h4"
            component="div"
          >
            {dataToUpdate.fname} {dataToUpdate.lname}
          </Typography>
          <Grid container spacing={2}>
            <Grid
              style={{ display: 'flex', justifyContent: 'center' }}
              item
              xs={12}
              md={6}
            >
              <Demo>
                <List dense={true}>
                  {/* <ListItem>
                    <ListItemText
                      primary={`Owner of the car: ${state.fname} ${state.lname}`}
                    />
                  </ListItem> */}
                  <ListItem>
                    <ListItemText
                      primary={`Username: ${dataToUpdate.username}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`Type: ${dataToUpdate.type}`} />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={`Location: ${dataToUpdate.location_name}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`Phone: ${dataToUpdate.phone}`} />
                  </ListItem>

                  <ListItem>
                    <ListItemText
                      primary={`Status: ${
                        dataToUpdate.active === '1' ? 'Active' : 'Inactive'
                      }`}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemText
                      primary={`Date: ${new Date(dataToUpdate.time)
                        .toLocaleString('en-GB', {
                          timeZone: 'Africa/Kigali',
                        })
                        .substring(0, 10)}`}
                    />
                  </ListItem>
                </List>
              </Demo>
            </Grid>
            <Grid item xs={12} md={6}>
              <Demo>
                {/* begining  */}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    margin: '20px',
                  }}
                >
                  <div
                    style={{
                      marginTop: '20px',
                      marginBottom: '20px',
                    }}
                  >
                    <TextField
                      id="password"
                      label="New password*"
                      defaultValue=""
                      style={{ width: '300px' }}
                    />
                  </div>

                  <p style={{ marginLeft: '15%' }} id="message">
                    {message}
                  </p>

                  {/* Ending */}

                  <Button
                    style={{ width: '200px' }}
                    variant="outlined"
                    startIcon={<CreateOutlinedIcon />}
                    onClick={updateUser}
                  >
                    Change Password
                  </Button>
                </div>
                <List dense={true}>
                  <ListItem>
                    <Button
                      variant="outlined"
                      onClick={() => navigate(-1)}
                      startIcon={<ArrowBackIosIcon />}
                    >
                      Back
                    </Button>
                  </ListItem>
                </List>
              </Demo>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}
