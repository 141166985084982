import LeftNav from '../components/Leftnav';
import TopBar from '../components/Topbar';
import Dashboar from '../components/Dashboard';

export default function Dashboard() {
  return (
    <div className="App">
      <div className="left-side">
        <LeftNav />
      </div>
      <div className="right-side">
        <div>
          <TopBar page="All" />
        </div>
        <Dashboar />
      </div>
    </div>
  );
}
