import { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/Addacgroup.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useToken } from '../components/auth';
// import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router';

export default function UsersDisplay() {
  const navigate = useNavigate();
  const url = process.env.REACT_APP_BASE_URL;
  const [token] = useToken();
  // Getting existing users
  const [userDataa, setUserDataa] = useState('');

  useEffect(() => {
    const userData = async () => {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/signup`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setUserDataa(data);
      } catch (error) {
        console.log(error);
      }
    };
    userData();
  }, [token, url]);

  // displaying users

  return (
    <div>
      {Object.keys(userDataa).length !== 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead style={{ background: 'black' }}>
              <TableRow className="">
                <TableCell>
                  <h6 style={{ color: '#fff' }}>Names</h6>
                </TableCell>
                <TableCell align="right">
                  {' '}
                  <h6 style={{ color: '#fff' }}>Useraname</h6>
                </TableCell>
                <TableCell align="right">
                  <h6 style={{ color: '#fff' }}>Phone</h6>
                </TableCell>
                <TableCell align="right">
                  <h6 style={{ color: '#fff' }}>Type</h6>
                </TableCell>
                <TableCell align="right">
                  <h6 style={{ color: '#fff' }}>Location</h6>
                </TableCell>
                <TableCell align="right">
                  <h6 style={{ color: '#fff' }}>Status</h6>
                </TableCell>
                <TableCell align="right">
                  <h6 style={{ color: '#fff' }}>More</h6>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {userDataa.getUser.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.fname} {row.lname}
                  </TableCell>
                  <TableCell align="right">{row.username}</TableCell>
                  <TableCell align="right">{row.phone}</TableCell>
                  <TableCell align="right">{row.type}</TableCell>
                  <TableCell align="right">{row.location_name}</TableCell>
                  <TableCell align="right">
                    {row.active === '1' ? 'Active' : 'Inactive'}
                  </TableCell>
                  <TableCell align="right">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Edit
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            navigate('/update-profile', {
                              state: { row },
                            })
                          }
                        >
                          Update profile
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            navigate('/new-password', { state: { row } })
                          }
                        >
                          Change password
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </TableCell>
                </TableRow>
              ))}
              {Object.keys(userDataa).length === 0 && <p>Loading........</p>}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
