import LeftNav from '../components/Leftnav';
import TopBar from '../components/Topbar';
import '../css/Cars.css';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser, useToken } from '../components/auth';
import TextField from '@mui/material/TextField';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

export default function WorkerDataById() {
  const url = process.env.REACT_APP_BASE_URL;
  const [token] = useToken();
  const user = useUser();
  const { id } = user;
  const navigate = useNavigate();
  const { state } = useLocation();
  const [dataToUpdate, setDataToUpdate] = useState(state.row);
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState(dataToUpdate.bank_id);
  const [locations, setLocations] = useState([]);
  const [locationNew, setLocationNew] = useState(dataToUpdate.location);
  const [message, setMessage] = useState('');
  const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const openPopup = () => {
    setOpen(true);
  };
  useEffect(() => {
    async function locationsList() {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/signup/locations`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setLocations(data);
      } catch (error) {
        console.log(error);
      }
    }
    locationsList();
  }, [token, url]);

  function handleLocationChanges(event) {
    setLocationNew(event.target.value);
  }

  useEffect(() => {
    async function BankList() {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${url}/workers/banks`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        };

        const { data } = await axios.request(config);
        setBanks(data);
      } catch (error) {
        console.log(error);
      }
    }
    BankList();
  }, [token, url]);
  function handleBankChanges(event) {
    setSelectedBank(event.target.value);
  }

  async function upDriver() {
    const names = document.querySelector('#names').value;
    const idnum = document.querySelector('#idnum').value;
    const account = document.querySelector('#account').value;
    const phone = document.querySelector('#phone').value;

    if (
      names === '' ||
      selectedBank === 0 ||
      locationNew === '' ||
      locationNew === 0 ||
      account === ''
    ) {
      document.querySelector('#message').style.color = 'red';
      setMessage('Please fill out all the required fields');
    } else {
      document.querySelector('#message').style.color = 'blue';
      setMessage('Tegereza......');
      try {
        const datass = JSON.stringify({
          names,
          idnum,
          bank: selectedBank,
          location: locationNew,
          account,
          phone,
          driverId: dataToUpdate.id,
        });
        const config = {
          method: 'put',
          maxBodyLength: Infinity,
          url: `${url}/workers/update`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          data: datass,
        };

        const { data } = await axios.request(config);
        if (data.message === 'Updated successfully!') {
          setDataToUpdate({
            ...dataToUpdate,
            names,
            idnum,
            phone,
            account,
            bank_id: selectedBank,
            location: locationNew,
          });
          document.querySelector('#message').style.color = 'green';
          setMessage(data.message);
        }
        document.querySelector('#message').style.color = 'red';
        setMessage(data.message);
      } catch (error) {
        console.log(error);
        document.querySelector('#message').style.color = 'red';
        setMessage(`Worker could not be updated`);
      }
    }
  }

  async function deleteData() {
    document.querySelector('#message').innerHTML = '';
    document.querySelector('#message').style.color = 'blue';
    try {
      document.querySelector('#message').innerHTML = 'Tegereza....';
      const datass = JSON.stringify({
        worker: state.row.id,
        user: id,
      });
      const config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${url}/workers/delete`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        data: datass,
      };

      const { data } = await axios.request(config);
      if (data.message === 'Removed') {
        navigate(-1);
        document.querySelector('#message').style.color = 'green';
      } else {
        document.querySelector('#message').innerHTML = data.message;
        document.querySelector('#message').style.color = 'red';
      }
    } catch (error) {
      console.log(error);
      document.querySelector('#message').innerHTML = 'Unable to delete data';
      document.querySelector('#message').style.color = 'red';
    }
  }

  return (
    <div className="App">
      <div className="left-side">
        <LeftNav />
      </div>
      <div className="right-side">
        <div>
          <TopBar page="Depense" />
        </div>
        {/* displaying here */}
        <Box key={state.row.id} sx={{ flexGrow: 1, maxWidth: '90%' }}>
          <Typography
            style={{ display: 'flex', justifyContent: 'center' }}
            sx={{ mt: 4, mb: 2 }}
            variant="h4"
            component="div"
          >
            {state.row.names}
          </Typography>
          <Grid container spacing={2}>
            <Grid
              style={{ display: 'flex', justifyContent: 'center' }}
              item
              xs={12}
              md={6}
            >
              <Demo>
                <List dense={true}>
                  {/* <ListItem>
                    <ListItemText
                      primary={`Owner of the car: ${state.row.fname} ${state.row.lname}`}
                    />
                  </ListItem> */}
                  <ListItem>
                    <ListItemText primary={`Name: ${dataToUpdate.names}`} />
                  </ListItem>

                  <ListItem>
                    <ListItemText primary={`ID: ${dataToUpdate.idnum}`} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`Phone:  ${dataToUpdate.phone}`} />
                  </ListItem>

                  {/* <ListItem>
                    <ListItemText
                      primary={`Phone:  ${dataToUpdate.location_name}`}
                    />
                  </ListItem> */}

                  <ListItem>
                    <ListItemText
                      primary={`Account: ${dataToUpdate.account}`}
                    />
                  </ListItem>

                  {/* <ListItem>
                    <ListItemText primary={`Bank: ${dataToUpdate.bank}`} />
                  </ListItem> */}
                </List>
              </Demo>
            </Grid>
            <Grid item xs={12} md={6}>
              <Demo>
                {/* delete popup */}
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="draggable-dialog-title"
                >
                  <DialogTitle
                    style={{ cursor: 'move' }}
                    id="draggable-dialog-title"
                  >
                    Delete Data
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Are you sure you want to delete this data?
                    </DialogContentText>
                    <p id="message"></p>
                  </DialogContent>
                  <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                      No
                    </Button>
                    <Button onClick={deleteData}>Yes</Button>
                  </DialogActions>
                </Dialog>
                {/* End of delete popup */}
                <List dense={true}>
                  <ListItem style={{ marginBottom: '30px' }}>
                    <Button
                      style={{ color: 'red' }}
                      variant="outlined"
                      startIcon={<DeleteIcon />}
                      onClick={openPopup}
                    >
                      Delete
                    </Button>
                  </ListItem>

                  {/* editing driver */}

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                      margin: '20px',
                    }}
                  >
                    <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                      <TextField
                        id="names"
                        label="Names*"
                        defaultValue={dataToUpdate.names}
                      />
                      <TextField
                        style={{ marginLeft: '20px' }}
                        id="idnum"
                        label="ID"
                        defaultValue={dataToUpdate.idnum}
                      />
                    </div>

                    <div>
                      <FormControl sx={{ m: 1, width: 200, marginTop: 0 }}>
                        <InputLabel id="demo-simple-select-label">
                          Bank*
                        </InputLabel>
                        <Select
                          id="bank"
                          label="Bank*"
                          value={selectedBank}
                          onChange={handleBankChanges}
                        >
                          <MenuItem key={0} value={0}>
                            <span>Hitamo Bank</span>
                          </MenuItem>
                          {banks.length !== 0 &&
                            banks.bankLists.map((loc) => (
                              <MenuItem key={loc.id} value={loc.id}>
                                {loc.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>

                      <TextField
                        id="account"
                        label="Account*"
                        defaultValue={dataToUpdate.account}
                      />
                    </div>

                    <div>
                      <FormControl sx={{ m: 1, width: 200, marginTop: 0 }}>
                        <InputLabel id="demo-simple-select-label">
                          Location*
                        </InputLabel>
                        <Select
                          id="location"
                          label="Location*"
                          value={locationNew}
                          onChange={handleLocationChanges}
                        >
                          <MenuItem key={0} value={0}>
                            <span>Hitamo Location yumukozi</span>
                          </MenuItem>
                          {locations.length !== 0 &&
                            locations.locationLists.map((loc) => (
                              <MenuItem key={loc.id} value={loc.id}>
                                {loc.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <TextField
                        id="phone"
                        label="Phone"
                        defaultValue={dataToUpdate.phone}
                      />
                    </div>

                    <p style={{ marginLeft: '15%' }} id="message">
                      {message}
                    </p>

                    {/* Ending */}

                    <Button
                      style={{ width: '200px' }}
                      variant="outlined"
                      startIcon={<CreateOutlinedIcon />}
                      onClick={upDriver}
                    >
                      Edit
                    </Button>
                  </div>

                  {/* End of editing driver */}

                  <ListItem>
                    <Button
                      variant="outlined"
                      onClick={() => navigate(-1)}
                      startIcon={<ArrowBackIosIcon />}
                    >
                      Back
                    </Button>
                  </ListItem>
                </List>
              </Demo>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}
