import Table from 'react-bootstrap/Table';

function ExcelData({ data, cars }) {
  const newData = data.filter((dat) => typeof dat[0] !== 'string');

  const dataWithOwner = newData.map((newdat) => {
    const plate = newdat[2] ? newdat[2].toUpperCase() : '';
    const car = cars.carsData.filter((car) => car.plate === plate);
    const owner = Object.keys(car).length === 0 ? '' : car[0].ownerName;
    return [...newdat, owner];
    //return [...newdat];
  });
  //console.log(dataWithOwner);
  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>#</th>
          <th>Plate</th>
          <th>Owner</th>
          <th>Date</th>
          <th>No Tickets</th>
          <th>Amount(Rwf)</th>
        </tr>
      </thead>
      <tbody>
        {dataWithOwner.map((dt) => (
          <tr key={dt[0]}>
            <td>{dt[0]}</td>
            <td>{dt[2]}</td>
            <td>{dt[7]}</td>
            <td>{dt[4]}</td>
            <td>{dt[5]}</td>
            <td>{dt[6]}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default ExcelData;
