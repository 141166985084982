import { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { useToken } from '../components/auth';
import { LoggedIn } from '../components/loggedin';
import logo from '../assets/kivubelt-logo.jpg';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://rmsoft.rw">
        RMsoft ltd
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn() {
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [token, setToken] = useToken();
  const logTheHellIn = async (event) => {
    event.preventDefault();
    const postData = JSON.stringify({
      username: document.querySelector('#email').value,
      password: document.querySelector('#password').value,
    });
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/login`,
      headers: { 'Content-Type': 'application/json' },
      data: postData,
    };
    try {
      setMessage('Wait......');
      document.querySelector('#message').style.color = 'blue';
      const response = await axios.request(config);
      setToken(response.data.token);
      if (token !== '') navigate('/dashBoard');
    } catch (error) {
      document.querySelector('#message').style.color = 'red';
      setMessage('Incorrect credentials');
    }
  };

  return (
    <>
      <LoggedIn />
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img
              className="logo"
              style={{ height: '60px' }}
              src={logo}
              alt="logo"
            />

            <Box component="form" noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Username"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={logTheHellIn}
                sx={{ mt: 3, mb: 2 }}
                style={{ backgroundColor: '#727cf5' }}
              >
                Sign In
              </Button>
              <span id="message" style={{ color: '#727cf5' }}>
                {message}
              </span>
            </Box>
          </Box>
          <Copyright style={{ color: '#727cf5' }} sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
    </>
  );
}
