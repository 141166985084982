import LeftNav from '../components/Leftnav';
import TopBar from '../components/Topbar';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CarList from '../components/carList';
import '../css/Cars.css';
import AddCar from '../components/AddCar';
import AddOwner from '../components/AddOwner';
import OwnersList from '../components/OwnersList';

export default function Cars() {
  return (
    <div className="App">
      <div className="left-side">
        <LeftNav />
      </div>
      <div className="right-side">
        <div>
          <TopBar page="cars" />
        </div>
        {/* Tab here */}
        <br />
        <Tabs
          defaultActiveKey="cars"
          id="uncontrolled-tab-example"
          className="mb-2"
        >
          <Tab eventKey="cars" title="Cars">
            <AddCar />
            <div className="cars-list">
              <CarList />
            </div>
          </Tab>
          <Tab eventKey="owners" title="Owners">
            <AddOwner />
            <div className="cars-list">
              <OwnersList />
            </div>
          </Tab>
        </Tabs>
        {/* Tabs ends here */}
      </div>
    </div>
  );
}
