import LeftNav from '../components/Leftnav';
import TopBar from '../components/Topbar';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../css/Addacgroup.css';
import AddAcgroup from '../components/AddAcgroup';
import AddAbsentCars from '../components/AbsentCars';

export default function Acgroup() {
  return (
    <div className="App">
      <div className="left-side">
        <LeftNav />
      </div>
      <div className="right-side">
        <div>
          <TopBar page="AC Group" />
        </div>
        {/* Tab here */}
        <br />
        <Tabs
          defaultActiveKey="acgroup"
          id="uncontrolled-tab-example"
          className="mb-2"
        >
          <Tab eventKey="acgroup" title="AC Group Data">
            <AddAcgroup style={{ width: '850px' }} />
          </Tab>
          <Tab eventKey="add" title="Add absent car">
            <AddAbsentCars />
          </Tab>
        </Tabs>

        {/* Tabs ends here */}
      </div>
    </div>
  );
}
