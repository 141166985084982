import * as React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { fetchCars } from '../redux/cars/carSlice';
import { useToken } from './auth';
import { useNavigate } from 'react-router';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/Info';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

let rows = [];

export default function CustomizedTables() {
  const navigate = useNavigate();
  const [token] = useToken();
  const { carData, isSuccess, loading } = useSelector((state) => state.cars);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCars(token));
  }, [dispatch, token]);

  if (isSuccess) rows = carData.carsData;

  if (loading) return <p>Loading......</p>;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Plate</StyledTableCell>
            <StyledTableCell>Type</StyledTableCell>
            <StyledTableCell>Owner</StyledTableCell>
            <StyledTableCell>Driver</StyledTableCell>
            <StyledTableCell>Chassis</StyledTableCell>
            <StyledTableCell>More</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.plate}>
              <StyledTableCell component="th" scope="row">
                {row.plate}
              </StyledTableCell>
              <StyledTableCell>{row.type}</StyledTableCell>
              <StyledTableCell>
                <Link to={`/owners/${row.owner}`}>{row.ownerName}</Link>{' '}
              </StyledTableCell>
              <StyledTableCell>{row.driverName}</StyledTableCell>
              <StyledTableCell>{row.chassis}</StyledTableCell>
              <StyledTableCell>
                <Button
                  variant="outlined"
                  onClick={() => navigate('/carinfo', { state: { row } })}
                  startIcon={<InfoIcon />}
                >
                  More
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
