import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import '../css/Cars.css';
import { fetchData } from '../redux/cars/ownerSlice';
import { useToken } from '../components/auth';
import CarsByACData from './CarsByACData';
import { useNavigate } from 'react-router';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export function DisplayAc({ owner, sdate, edate }) {
  const navigate = useNavigate();
  const { ownersData, isSuccess, loading } = useSelector(
    (state) => state.owners
  );
  const [token] = useToken();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchData(token));
  }, [dispatch, token]);
  let own = parseInt(owner);
  if (typeof own != 'number') {
    own = 0;
  }

  const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));

  if (loading) return <p>Loading......</p>;
  let selected = [];

  if (isSuccess)
    selected = ownersData.ownerData.filter((owners) => owners.id === own);

  return selected.map((ow) => (
    <Box key={ow.id} sx={{ flexGrow: 1, maxWidth: '90%' }}>
      <Typography
        style={{ display: 'flex', justifyContent: 'center' }}
        sx={{ mt: 4, mb: 2 }}
        variant="h4"
        component="div"
      >
        {ow.fname} {ow.lname}
      </Typography>
      <Grid container spacing={2}>
        <Grid
          style={{ display: 'flex', justifyContent: 'center' }}
          item
          xs={12}
          md={6}
        >
          <Demo>
            <List dense={true}>
              <ListItem>
                <ListItemText primary={`ID: ${ow.idnum}`} />
              </ListItem>
              <ListItem>
                <ListItemText primary={`Phone: ${ow.phone}`} />
              </ListItem>
              <ListItem>
                <ListItemText primary={`Bank: ${ow.bank}`} />
              </ListItem>
              <ListItem>
                <ListItemText primary={`Acount: ${ow.account}`} />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={`Registered: ${ow.time
                    .slice(0, 19)
                    .replace('T', ' ')}`}
                />
              </ListItem>
            </List>
          </Demo>
        </Grid>
        <Grid item xs={12} md={6}>
          <Demo>
            <List dense={true}>
              <ListItem>
                <Button
                  variant="outlined"
                  onClick={() =>
                    navigate('/ACgroup', { state: { sdate, edate } })
                  }
                  startIcon={<ArrowBackIosIcon />}
                >
                  Back
                </Button>
              </ListItem>
            </List>
          </Demo>
        </Grid>
      </Grid>
      <Typography
        style={{ display: 'flex', justifyContent: 'center' }}
        sx={{ mt: 4, mb: 2 }}
        variant="h4"
        component="div"
      >
        AC Group Data
      </Typography>
      <CarsByACData owner={own} sdate={sdate} edate={edate} />
    </Box>
  ));
}
